import { DeviceRecord, IridiumAccount } from "@somewear/api";
import { selectActiveOrganizationId, selectActiveWorkspaceId } from "@somewear/auth";
import {
	isBorrowedDevice,
	selectAllDevices,
	selectDeviceBySerial,
	selectDevicesInWorkspace,
} from "@somewear/device";
import type { IDevice } from "@somewear/model";
import { getDictionaryValue } from "@somewear/model";
import { selectDeviceSummary, selectShowBorrowed } from "@somewear/settings";
import { selectWorkspaceEntities } from "@somewear/workspace";
import { createDeepEqualSelector } from "@web/common/utils";
import { createSelector } from "reselect";

import type { RootState } from "../rootReducer";

interface IDeviceUsage {
	device: IDevice;
	usage: number;
}

export const selectDeviceByIdWithIsBorrowed = createDeepEqualSelector(
	[selectDeviceBySerial, selectActiveOrganizationId, selectWorkspaceEntities],
	(device, activeOrganizationId, workspaceDict) => {
		if (device === undefined) return undefined;
		const workspace = getDictionaryValue(workspaceDict, device.workspaceId);
		const isBorrowed = isBorrowedDevice(
			device.organizationId,
			workspace?.organizationId,
			activeOrganizationId
		);
		return { isBorrowed: isBorrowed, ...device };
	}
);

export const selectDevicesForActiveOrganization = createDeepEqualSelector(
	[
		selectAllDevices,
		selectActiveOrganizationId,
		selectDeviceSummary,
		selectWorkspaceEntities,
		selectShowBorrowed,
	],
	(devices, activeOrganizationId, deviceStatus, workspaceDict, showBorrowed) => {
		const sortedDevices = [...devices]
			.map<IDevice>((device) => {
				const workspace = getDictionaryValue(workspaceDict, device.workspaceId);
				const isBorrowed = isBorrowedDevice(
					device.organizationId,
					workspace?.organizationId,
					activeOrganizationId
				);
				return { isBorrowed: isBorrowed, ...device };
			})
			.filter((device) => {
				if (activeOrganizationId === undefined) return undefined;
				const isOwnedByOrg = device.organizationId === activeOrganizationId;
				if (!showBorrowed) return isOwnedByOrg;
				return isOwnedByOrg || device.isBorrowed;
			})
			.sort((a, b) => {
				if (a.isBorrowed && !b.isBorrowed) return 1;
				if (!a.isBorrowed && b.isBorrowed) return -1;
				const aTime =
					deviceStatus.find((it) => it.serial === a.serial)?.lastActive?.seconds ?? 0;
				const bTime =
					deviceStatus.find((it) => it.serial === b.serial)?.lastActive?.seconds ?? 0;
				if (aTime !== bTime) return bTime - aTime;
				return a.serial.localeCompare(b.serial);
			});
		return sortedDevices;
	}
);

export const selectDeviceUsageWarningsForActiveOrganization = createSelector(
	[selectDevicesForActiveOrganization],
	(devices) => {
		const usages: IDeviceUsage[] = devices
			.mapNotNull((it) => {
				if (it.kbUsed === undefined || it.kbIncluded === undefined) return undefined;
				if (it.kbIncluded === 0) {
					return {
						device: it,
						usage: -1,
					};
				}
				return {
					device: it,
					usage: Math.ceil((100 * it.kbUsed) / it.kbIncluded),
				};
			})
			.filter((it) => it.usage > 75 && it.device.gateway === DeviceRecord.Gateway.COMMERCIAL)
			.sort((a, b) => b.usage - a.usage);

		return usages;
	}
);

export const selectDeviceUsageAlertsForActiveOrganization = createSelector(
	[selectDeviceUsageWarningsForActiveOrganization],
	(warnings) => {
		return warnings.filter((it) => it.usage > 100);
	}
);

export const selectHasDeviceUsageWarnings = createSelector(
	[selectDeviceUsageWarningsForActiveOrganization, selectDeviceUsageAlertsForActiveOrganization],
	(warnings, alerts) => {
		return warnings.length > 0;
	}
);

export const selectHasDeviceUsageAlerts = createSelector(
	[selectDeviceUsageWarningsForActiveOrganization, selectDeviceUsageAlertsForActiveOrganization],
	(warnings, alerts) => {
		return alerts.length > 0;
	}
);

export const selectActiveDeviceCount = createSelector(
	[selectDevicesForActiveOrganization],
	(orgDevices) => {
		const commercialDevices = orgDevices.filter(
			(device) => device.gateway === DeviceRecord.Gateway.COMMERCIAL
		);

		const emssDevices = orgDevices.filter(
			(device) => device.gateway === DeviceRecord.Gateway.EMSS
		);

		const activatedCommercialDevices = commercialDevices.filter(
			(device) => device.plan?.iridiumStatus === IridiumAccount.Status.ACTIVE
		);

		return emssDevices.length + activatedCommercialDevices.length;
	}
);

export const selectHasInactiveDevices = createSelector(
	[selectDevicesForActiveOrganization, selectActiveDeviceCount],
	(orgDevices, activeDeviceCount) => {
		return orgDevices.length > activeDeviceCount;
	}
);

// todo: use create selector here
export const selectActiveWorkspaceDeviceSerials = (state: RootState) => {
	const activeWorkspaceId = selectActiveWorkspaceId(state);
	if (activeWorkspaceId === undefined) return [];
	const devices = selectDevicesInWorkspace(state, activeWorkspaceId);
	if (devices === undefined) return [];
	return devices.map((it) => it.serial);
};

import classNames from "classnames";
import * as React from "react";

interface IProps {
	text?: string;
	fullScreen?: boolean;
}

const Loader: React.FunctionComponent<IProps> = (props: IProps) => (
	<div
		className={classNames({
			"w-full h-full fixed left-0 top-0 bg-white/50 flex z-[9998]": props.fullScreen,
		})}
	>
		<div className="flex flex-col justify-center m-auto">
			{props.text ? <div className="text-[18px] font-normal mb-[40px] text-center text-uppercase text-black">{props.text}</div> : <div />}
			<div className="loader-container">
				<div className="bg-blue-500 w-[1em] h-[2em] before:animate-load-1 before:top-0 after:top-0 after:animate-load-1 animate-load-1 before:absolute before:bg-blue-500 after:bg-blue-500 after:absolute before:w-[1em] before:h-[2em] after:w-[1em] after:h-[2em] text-blue-500 relative text-[8px] indent-[-9999em] translate-z-0 m-auto animation-delay-[-0.16s] before:left-[-1.5em] before:animation-delay-[-0.32s] after:left-[1.5em]" />
			</div>
		</div>
	</div>
);

export default Loader;

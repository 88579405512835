import type { OnCompleteHandler, Submitter } from "@somewear/model";
import { getSubmitAction, isFunctionSubmitter } from "@somewear/model";
import {
	SomewearNotificationStatus,
	useOnCompleteHandler,
	useSelectNotificationById,
} from "@somewear/notification";
import { LoadingDots, RenderIf } from "@somewear/ui";
import * as React from "react";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import type { ButtonProps } from "../components/ui/button";
import { Button } from "../components/ui/button";

type Props = {
	className?: string;
	submitLabel?: string;

	color?: "primary" | "secondary";
	forcePending?: boolean;
	disabled?: boolean;
	onComplete?: OnCompleteHandler;
	validate?: () => Promise<boolean>;
} & ButtonProps;

export type SubmitProps<T = unknown, D = unknown> = {
	submitter: Submitter<T, D>;
	state?: D;
};

type CombinedProps<T = unknown, D = unknown> = Props & SubmitProps<T, D>;

function PendingButtonInner<T = unknown, D = unknown>(
	props: CombinedProps<T, D>,
	ref: React.ForwardedRef<HTMLButtonElement>
) {
	const { variant = 'default' } = props;

	const dispatch = useDispatch();

	const onSubmit = isFunctionSubmitter(props.submitter) ? props.submitter.onSubmit : undefined;

	const [requestId, setRequestId] = useState<string | undefined>();

	const doSubmit = useCallback(async () => {
		if (props.validate && !(await props.validate())) {
			console.log("Validation failed");
			return;
		}

		const submitAction = getSubmitAction(props.submitter, props.state);
		setRequestId(submitAction?.payload?.requestId);

		if (submitAction !== undefined) {
			dispatch(submitAction);
		} else if (onSubmit !== undefined) {
			const isComplete = onSubmit(props.state as D) ?? true;
			if (isComplete && props.onComplete !== undefined) props.onComplete(true);
		} else {
			console.error("Unable to submit: no action or function");
		}
	}, [props, onSubmit, dispatch]);

	const notification = useSelectNotificationById(requestId);
	const isPending =
		props.forcePending || notification?.status === SomewearNotificationStatus.pending;

	useOnCompleteHandler(props.onComplete, notification, requestId);

	return (
		<Button
			ref={ref}
			variant={variant}
			className={props.className}
			onClick={() => {
				doSubmit();
			}}
			{...props}
		>
			<ButtonContent>
				<RenderIf condition={isPending}>
					<LoadingDots />
				</RenderIf>
				<RenderIf condition={!isPending}>{props.submitLabel ?? "Submit"}</RenderIf>
			</ButtonContent>
		</Button>
	);
}

// ref: https://fettblog.eu/typescript-react-generic-forward-refs/#option-1%3A-type-assertion
export const PendingButton = React.forwardRef(PendingButtonInner) as <T = unknown, D = unknown>(
	props: CombinedProps<T, D> & { ref?: React.ForwardedRef<HTMLButtonElement> }
) => ReturnType<typeof PendingButtonInner>;

const ButtonContent = styled.div`
	min-height: 19px;
	display: flex;
	align-items: center;
`;

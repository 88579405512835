import { useMemo, useState } from "react";
import * as React from "react";
import styled from "styled-components";

import { RenderIf } from "../../common/RenderIf";
import { Input } from "../ui/input";
import type { SubmitProps } from ".";
import { SomewearDialog } from ".";

type CombinedProps<T = unknown, D = unknown> = SubmitProps<T, D> & {
	title: string;
	confirmKeyword?: string;
	confirmPrompt?: string;
	confirmDescription?: string;
};

export function ConfirmationDialog<T = unknown, D = unknown>(props: CombinedProps<T, D>) {
	const [inputValue, setInputValue] = useState<string | undefined>(undefined);

	const isValid = useMemo(() => {
		if (props.confirmKeyword === undefined) return true;
		return inputValue === props.confirmKeyword;
	}, [inputValue, props.confirmKeyword]);

	return (
		<SomewearDialog {...props} submitDisabled={!isValid || props.submitDisabled}>
			<Container className={`flex flex-col mt-2 items-center w-full`}>
				<RenderIf condition={Boolean(props.icon)}>
					<div className={`dialog-icon`}>{props.icon}</div>
				</RenderIf>

				<div className={`dialog-content w-full`}>{props.children}</div>

				<RenderIf condition={Boolean(props.confirmKeyword && props.confirmPrompt)}>
					<div className="bg-gray-100 h-2 w-[calc(100%_+_4rem)] -mx-8 my-6" />

					<div className={`w-full mb-6 mt-2 text-sm`}>{props.confirmPrompt}</div>

					<div className={`flex mb-6 w-full`}>
						<Input
							placeholder={props.confirmKeyword}
							className={`max-w-[21rem] w-full`}
							onChange={(e) => setInputValue(e.target.value)}
						/>
					</div>
				</RenderIf>

				<RenderIf condition={Boolean(props.confirmDescription)}>
					<div className={`text-xs leading-5.5 italic text-gray-500 mb-4`}>{props.confirmDescription}</div>
				</RenderIf>
			</Container>
		</SomewearDialog>
	);
}

const Container = styled.div`
	font-family: ${(props) => props.theme.fonts.body};

	h1 {
		font-size: 1rem;
		font-weight: 500;
		color: #333;
		margin-top: 0.75rem;
		margin-bottom: 1.25rem;
		border-bottom: none;
		padding: 0;

		&.style-warning {
			color: hsl(var(--somewear-red-500));
		}
	}

	.dialog-icon {
		background: #f5f6f6;
		height: 54px;
		width: 54px;
		border-radius: 27px;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			width: 24px;
			height: 24px;
			fill: #7a8085;
		}
	}

	div.dialog-content {
		margin-bottom: 0;
	}

	&.style-warning {
		.dialog-icon {
			background: rgba(230, 69, 18, 0.09);

			svg {
				fill: hsl(var(--somewear-red-500));
			}
		}
	}

	.dialog-content {
		margin-bottom: 1rem;
		text-align: center;
		width: 100%;
		font-size: 0.875rem;

		h2 {
			font-size: 1rem;
			font-weight: 500;
			color: #000;
			padding-bottom: 0.25rem;
		}
	}
`;

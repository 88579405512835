import * as React from "react";

export const LoadingDots: React.FC = () => {
	const sizeClasses = 'w-2 h-2';
	const themeClass = 'bg-current';

	return (
		<div className="flex justify-center items-center h-8 overflow-hidden">
			<div className="relative flex space-x-2">
				<div className={`rounded-full ${sizeClasses} ${themeClass} animate-grow-1`}></div>
				<div className={`rounded-full ${sizeClasses} ${themeClass} animate-grow-2`}></div>
				<div className={`rounded-full ${sizeClasses} ${themeClass} animate-grow-3`}></div>
			</div>
		</div>
	);
};

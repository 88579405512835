export enum IntervalCategory {
	Somewear = "Somewear",
	ATAK = "ATAK",
}

export interface TrackingInterval {
	id: string;
	logRate: number;
	shareRate: number;
	type: IntervalCategory;
	label: string;
	subLabel?: string;
}

export enum TrackingIntervalIds {
	ATAK5 = "atak5s",
	ATAK15 = "atak15s",
	ATAK30 = "atak30s",
	ATAK45 = "atak45s",
	ATAK1Min = "atak1min",
	ATAK2Min = "atak2min",
	ATAK5Min = "atak5min",
	ATAK10Min = "atak10min",
	ATAK30Min = "atak30min",
	ATAK1Hr = "atak1hr",
	OnFoot = "onFoot",
	Vehicle = "vehicle",
	Jump = "jump",
	OneMin = "oneMin",
	Custom1 = "custom1",
	Custom2 = "custom2",
}

export const trackingIntervals: TrackingInterval[] = [
	{
		id: TrackingIntervalIds.ATAK5,
		logRate: 5,
		shareRate: 5,
		type: IntervalCategory.ATAK,
		label: "5s",
	},
	{
		id: TrackingIntervalIds.ATAK15,
		logRate: 15,
		shareRate: 15,
		type: IntervalCategory.ATAK,
		label: "15s",
	},
	{
		id: TrackingIntervalIds.ATAK30,
		logRate: 30,
		shareRate: 30,
		type: IntervalCategory.ATAK,
		label: "30s",
	},
	{
		id: TrackingIntervalIds.ATAK45,
		logRate: 45,
		shareRate: 45,
		type: IntervalCategory.ATAK,
		label: "45s",
	},
	{
		id: TrackingIntervalIds.ATAK1Min,
		logRate: 60,
		shareRate: 60,
		type: IntervalCategory.ATAK,
		label: "1m",
	},
	{
		id: TrackingIntervalIds.ATAK2Min,
		logRate: 120,
		shareRate: 120,
		type: IntervalCategory.ATAK,
		label: "2m",
	},
	{
		id: TrackingIntervalIds.ATAK5Min,
		logRate: 300,
		shareRate: 300,
		type: IntervalCategory.ATAK,
		label: "5m",
	},
	{
		id: TrackingIntervalIds.ATAK10Min,
		logRate: 600,
		shareRate: 600,
		type: IntervalCategory.ATAK,
		label: "10m",
	},
	{
		id: TrackingIntervalIds.ATAK30Min,
		logRate: 1800,
		shareRate: 1800,
		type: IntervalCategory.ATAK,
		label: "30m",
	},
	{
		id: TrackingIntervalIds.ATAK1Hr,
		logRate: 3600,
		shareRate: 3600,
		type: IntervalCategory.ATAK,
		label: "1h",
	},
	{
		id: TrackingIntervalIds.OnFoot,
		logRate: 30,
		shareRate: 60,
		type: IntervalCategory.Somewear,
		label: "On-Foot",
		subLabel: "Logs: 30s | Shares: 1m",
	},
	{
		id: TrackingIntervalIds.Vehicle,
		logRate: 15,
		shareRate: 30,
		type: IntervalCategory.Somewear,
		label: "Vehicle",
		subLabel: "Logs: 15s | Shares: 30s",
	},
	{
		id: TrackingIntervalIds.Jump,
		logRate: 5,
		shareRate: 5,
		type: IntervalCategory.Somewear,
		label: "Jump",
		subLabel: "Logs: 5s | Shares: 5s",
	},
	{
		id: TrackingIntervalIds.OneMin,
		logRate: 60,
		shareRate: 60,
		type: IntervalCategory.Somewear,
		label: "Custom",
		subLabel: "Logs: 1m | Shares: 1m",
	},
	{
		id: TrackingIntervalIds.Custom1,
		logRate: 30,
		shareRate: 300,
		type: IntervalCategory.Somewear,
		label: "Custom",
		subLabel: "Logs: 30s | Shares: 5m",
	},
	{
		id: TrackingIntervalIds.Custom2,
		logRate: 60,
		shareRate: 600,
		type: IntervalCategory.Somewear,
		label: "Custom",
		subLabel: "Logs: 1m | Shares: 10m",
	},
];

export const lowSpeedRadioFrequencies = {
	LOWSPEEDUNKNOWN: 0,
	LOWSPEEDAMERICAS1: 925750000,
	LOWSPEEDAMERICAS2: 926500000,
	LOWSPEEDAMERICAS3: 927250000,
	LOWSPEEDEUROPE1: 869525000,
};

export const highSpeedRadioFrequencies = {
	HIGHSPEEDUNKNOWN: 0,
	HIGHSPEEDAMERICAS1: 905250000,
	HIGHSPEEDAMERICAS2: 908500000,
	HIGHSPEEDAMERICAS3: 911750000,
	HIGHSPEEDEUROPE1: 865500000,
};

export const PERSONAL_WORKSPACE_ID = "-1";
export const PERSONAL_WORKSPACE_NAME = "Personal";

export interface WorkspacePickerOption {
	color: string;
	name: string;
	id: string;
	organizationId?: string;
	hideIcon?: boolean;
}
